<template>
  <div class="w-100">
    <div class="row justify-center">
      <div
        class="tab pr-5 pl-4"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="changeTab(tab.category)"
      >
        <span
          style="font-size: 20px"
          :style="
            activeTab === tab.category ? 'color: #050505' : 'color: #050505'
          "
          :class="
            activeTab === tab.category
              ? 'ProximaNovaBold'
              : 'ProximaNovaSemiBold'
          "
          >{{ tab.category }}
          <hr
            style="
                      width: 50px;
                      opacity: 1;
                      color: #050505;
                      margin: 4px auto;
                      height: 2px;
                    "
            v-if="activeTab === tab.category"
        /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabName: {
      type: String,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: "",
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      this.$emit("activeTab", tab);
    },
  },
  beforeUpdate() {
    this.activeTab = this.tabName;
  },
  created() {
    this.activeTab = this.tabName;
  },
};
</script>

<style scoped>
.tab {
  cursor: pointer;
  display: contents;
}
.tab span {
  padding: 0px 10px;
}
</style>
